/**
 * Проверяем на валидность email
 * @author Alexey Ovchinnikov <ovchinnikov@megasreda.ru>
 * @param {string} input
 * @returns {number}
 */
const testEmail = (input) => {
  const pattern =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/

  return input ? pattern.test(input.trim().toLowerCase()) : false
}

const testPassword = (input) => {
  const errors = []

  if (!(input.length >= 6 && input.length <= 64)) {
    errors.push({ text: '6-64 символов', status: false })
  } else {
    errors.push({ text: '6-64 символов', status: true })
  }

  if (!/(?=.*?[a-z])/u.test(input)) {
    errors.push({ text: 'По крайней мере 1 строчную букву', status: false })
  } else {
    errors.push({ text: 'По крайней мере 1 строчную букву', status: true })
  }

  if (!/(?=.*?[A-Z])/u.test(input)) {
    errors.push({ text: 'По крайней мере 1 заглавную букву', status: false })
  } else {
    errors.push({ text: 'По крайней мере 1 заглавную букву', status: true })
  }

  if (!/(?=.*?[0-9])/u.test(input)) {
    errors.push({ text: 'По крайней мере 1 цифру', status: false })
  } else {
    errors.push({ text: 'По крайней мере 1 цифру', status: true })
  }

  if (/[а-яёА-ЯЁ]/g.test(input)) {
    errors.push({
      text: 'Может содержать буквы только латинского алфавита',
      status: false,
    })
  } else {
    errors.push({
      text: 'Может содержать буквы только латинского алфавита',
      status: true,
    })
  }

  return errors
}

export { testEmail, testPassword }
