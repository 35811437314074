import { testEmail } from '@/project/helpers/test'
export default {
  methods: {
    emailValidate() {
      if (this.inputValue && !testEmail(this.inputValue)) {
        this.message = 'Введите корректный email адрес!'
        this.status = 'error'
        return false
      } else {
        this.message = ''
        this.status = 'done'
        this.isEmptyValue = false
        return true
      }
    },
  },
}
