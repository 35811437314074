import { debounce } from 'lodash'
export default {
  model: {
    prop: 'value',
    event: 'change',
  },

  props: {
    /**
     * Двунаправленная привязка текста введённого в поле
     */
    value: {
      type: [String, Number, Date],
      default: '',
    },

    /**
     * Уникальное идентификатор поля ввода
     */
    id: {
      type: String,
      required: true,
    },

    /**
     * Уникальное имя поля ввода
     */
    name: {
      type: String,
      default: null,
    },

    /**
     * Видимая подпись к полю
     */
    label: {
      type: String,
      required: true,
    },
    maxLength: {
      type: [Number],
      default: null,
    },
    /**
     * Стиль поля ввода. Возможные варианты: "fill", "outline"
     */
    styles: {
      type: String,
      default: 'border',
      validator(value) {
        return ['fill', 'outline', 'border'].includes(value)
      },
    },

    /**
     * Флаг устанавливает обязательность заполнения поля
     */
    required: {
      type: Boolean,
      default: false,
    },

    /**
     * Флаг устанавливает блокировку поля
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: 'on',
    },

    /**
     * Флаг устанавливает показывать ли сторонею иконку или нет
     * если да, то в дефолтном состоянии и при ошибке выводится стороняя иконки (любой html)
     */
    icon: {
      type: Boolean,
      default: false,
    },

    /**
     * Объект с ошибкой
     */
    error: {
      type: Object,
      default: () => ({
        status: 'default',
        message: '',
      }),
    },

    /**
     * Флаг устанавливает возможность ввода нескольких слов
     */
    oneWord: {
      type: Boolean,
      default: false,
    },
    loverCase: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputValue: this.value,
      message: '',
      status: 'default',
    }
  },
  watch: {
    error() {
      if (this.error) {
        this.message = this.error.message
        this.status = this.error.status
      }
    },
    value() {
      this.inputValue = this.value
    },
    inputValue() {
      if (this.loverCase) {
        this.inputValue = this.inputValue?.toLowerCase()
      }
      if (this.oneWord) {
        if (this.maxLength) {
          this.inputValue = this.inputValue?.trim().slice(0, this.maxLength)
        } else {
          this.inputValue = this.inputValue?.trim()
        }
      } else if (this.maxLength) {
        this.inputValue = this.inputValue.slice(0, this.maxLength)
      }
    },
  },
  computed: {
    numberOfCharacters() {
      return this.inputValue ? this.inputValue.length : 0
    },
  },
  beforeUnmount() {
    this.debouncedHandler.cancel()
  },
  mounted() {
    this.debouncedHandler = debounce(() => {
      this.onChange()
    }, 500)
    if (
      this.value ||
      this.inputValue ||
      this.value === 0 ||
      this.inputValue === 0
    ) {
      this.$nextTick(() => {
        this.onChange()
      })
    }
  },
  methods: {
    focus() {
      const input = this.$refs.input
      input.focus()
    },

    /**
     * Обработчик события input
     */
    onInput(event) {
      this.inputValue = String(event.target.value)
      this.debouncedHandler()
      /**
       * Возвращает введённый текст при каждом вводе
       */
      this.$emit('onInput', this.inputValue)
    },

    /**
     * Обработчик события нажатия клавиши Enter
     */
    onEnter(event) {
      const input = this.$refs.input
      input.blur()
      /**
       * Нажатие клавиши enter возвращает event.target
       */
      this.onChange()
      this.$emit('onEnter', event.target.value)
    },

    /**
     * Обработчик события нажатия клавиши Tab
     */
    onTab(event) {
      // const input = this.$refs.input
      // input.blur()
      /**
       * Нажатие клавиши tab возвращает event.target
       */
      this.$emit('onTab', event.target)
    },
    /**
     * Обработчик события focus
     */
    onFocus(event) {
      this.message = ''
      this.status = 'active'
      this.$emit('onFocus', event.target)
    },

    /**
     * Обработчик события потери фокуса
     */
    onBlur() {
      this.$nextTick(() => {
        this.onChange()
      })
    },

    /**
     * Обработчик события увода мышки
     */
    onLeave() {
      this.$nextTick(() => {
        // this.onChange()
      })
    },

    /**
     * Метод для очистки поля
     */
    clear(_id, event) {
      const input = this.$refs.input
      input && input.focus()
      this.$nextTick(() => {
        this.inputValue = ''
        this.onChange()
        this.onFocus(event)
        /**
         * Генерируем событие при очистки поля
         */
        this.$emit('onClear', this.inputValue)
      })
    },
    requiredValidate() {
      if (this.required) {
        if (this.type === 'tel' && this.inputValue === '+7 (') {
          this.inputValue = ''
        }
        if (!this.inputValue) {
          this.message = 'Поле обязательно для заполнения.'
          this.status = 'error'
          this.isEmptyValue = true
          return false
        } else {
          this.message = ''
          this.status = 'done'
          this.isEmptyValue = false
          return true
        }
      } else {
        this.message = ''
        this.isEmptyValue = true
        return true
      }
    },
  },
}
