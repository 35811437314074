
import mixin from '../../mixins/mixin'
import emailValidator from './validators/emailValidator'

export default {
  name: 'CoreInputTextTypesEmail',
  mixins: [
    /**
     * Набор общих данных, методов и тд.
     */
    mixin,
    /**
     * Валидатор email адреса
     */
    emailValidator,
  ],

  props: {
    /**
     * Видимая подпись к полю
     */
    label: {
      type: String,
      default: 'Электронная почта',
    },

    /**
     * Связь с данными пользователя (если пользователь залогинен, то подставится его данные из профиля)
     */
    link: {
      type: String,
      default: null,
      validator(value) {
        return ['user'].includes(value)
      },
    },
  },
  fetch() {
    if (this.$auth.loggedIn && this.link === 'user') {
      this.inputValue = this.$auth.user.email
    }
  },

  methods: {
    validate() {
      const isValid = this.requiredValidate() && this.emailValidate()
      /**
       * Возвращает true если успешно пройдены все валидаторы
       */
      this.$emit('isValid', isValid)
      return isValid
    },

    /**
     * Обработчик события change
     */
    onChange() {
      this.validate()
      this.$emit('change', this.inputValue)
      this.$emit('onChange', this.inputValue)
    },
  },
}
